@import 'styles/variables.less';

@keyframes buttonLoading {
  0% {
    opacity: 0.2;
    background: @eggshell;
  }
  20% {
    opacity: 1;
    background: @eggshell;
  }
  100% {
    opacity: 0.2;
    background: @nueblack;
  }
}

.button {
  font-family: @gotham;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.4000000059604645px;

  text-align: center;

  cursor: pointer;
  display: inline-block;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: @nueblue none;
  color: #ffffff;

  margin: 0em 0.4em 0em 0.4em;
  padding: 16px;

  text-transform: none;
  text-shadow: none;

  text-align: center;
  text-decoration: none;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px #d1d5db inset;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  -webkit-tap-highlight-color: transparent;

  &:visited {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.88;
  }

  &.nueblue {
    background: @nueblue;
    border-color: @nueblue !important;
    color: @eggshell;
  }

  &.calmblue {
    background: @calmblue;
  }

  &.magenta {
    background: @magenta;
  }

  &.disabled {
    opacity: 0.4;
  }

  &.long {
    min-width: 220px;
  }

  &.fluid {
    width: 100%;
    margin: 0;
  }

  &.pill {
    height: 48px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    .icon.arrow-down {
      width: 12px;
    }
  }

  .loading {
    .dot {
      display: inline-block;
      font-size: 50px;
      animation-name: buttonLoading;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      height: 8px;
      background: @nueblue;
      width: 8px;
      border-radius: 8px;
      margin-right: 10px;
    }

    span:nth-child(2) {
      animation-delay: 0.2s;
    }
    span:nth-child(3) {
      animation-delay: 0.4s;
    }
    span:nth-child(4) {
      animation-delay: 0.6s;
    }
    span:nth-child(5) {
      animation-delay: 0.8s;
    }
  }
}

.button.transparent {
  background-color: rgba(255, 255, 255, 0.1);
}

.button.transparent.border {
  border: 2px solid @eggshell;
  color: @nueblue;
  &:hover {
    background: rgba(75, 121, 170, 0.08);
  }
}

.button.secondary {
  background: @changeyellow;
  color: @nueblue;
}

.button.eggshell {
  background: @eggshell;
  color: @nueblue;
}

.button.wellness {
  background: @wellness;
  color: @nueblue;
}

.button.compact {
  padding: 10px 0;
}

.button.icon {
  .icon {
    position: absolute;
    top: 18px;
  }
}

.button.icon.phone {
  .icon {
    top: 18px;
    width: 18px;
  }
}

.button.icon.readmore {
  .icon {
    top: 17px;
  }
}

.button {
  position: relative;

  .text {
    display: inline-block;
    margin-left: 28px;
  }
}