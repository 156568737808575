@import 'styles/responsive.less';
@import 'styles/variables.less';

.input {
  width: 100%;

  &.error {
    .input__error {
      display: flex;
    }
    label {
      color: red;
    }
    .input__container {
      background: #fbe9e6;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 10px 20px;

    margin-bottom: 10px;

    background: @eggshell;
    opacity: 0.8;
    border-radius: 10px;

    input {
      font-family: @gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      appearance: none;
      -webkit-appearance: none;
    }
  }

  &.hasvalue {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.6;
  }

  &__error,
  label {
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
  }

  &__error {
    color: @eggshell;
    height: 18px;

    display: none;
    height: 40px;
    background: #e87767;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}