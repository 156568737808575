@import 'styles/responsive.less';
@import 'styles/variables.less';

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 25px;
  cursor: pointer;
  pointer-events: auto;

  margin-bottom: 1em;

  background: @eggshell;
  width: 100%;

  &.error {
    color: red;
    background: #fbe9e6;
  }

  @media @desktop {
    width: 340px;
    input {
      width: 100%;
    }
  }

  border-radius: 50px;

  input:checked + .control {
    svg {
      visibility: visible;
    }
    background: @nueblue;
    opacity: 1;
  }

  input:focus + .control {
    border-color: @nueblue;
    opacity: 1;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .label {
    //styleName: CTA;
    font-family: @gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    padding-right: 10px;
  }

  .control {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    align-items: center;
    justify-content: space-around;
    border: 2px solid @nueblue;
    opacity: 0.2;

    svg {
      visibility: hidden;
    }
  }
}