@import 'styles/responsive.less';
@import 'styles/variables.less';

.select {
  width: 100%;

  &.error {
    .select__error {
      display: flex;
    }
    label {
      color: red;
    }
    .select__container {
      background: #F5F5F5;
    }
  }

  &__container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px 10px 20px;

    /* Eggshell */
    background: @eggshell;
    opacity: 0.8;
    border-radius: 10px;
  }

  &.hasValue {
    opacity: 1;
  }

  &__error,
  label {
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
  }

  &__error {
    color: @eggshell;
    height: 18px;

    display: none;
    height: 40px;
    background: #e87767;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 16px;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    -webkit-appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    font-family: @gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: @nueblue;
  }
}