@import 'styles/responsive.less';
@import 'styles/variables.less';

.signupStepContainer {
  padding: 0 20px;
  @media @desktop {
    display: flex;
    flex-direction: row;
    .signupStep__title {
      padding-right: 0.5em;
    }
    .bodycopy2 {
      font-family: @gotham;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.5px;
      text-align: left;
      padding-right: 3em;
    }
    .bodycopy3 {
      padding-left: 3em;
      font-family: @gotham;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.5px;
      text-align: justify;
      padding-right: 3em;
    }
  }

  &__left {
    flex: 0.4;
    h3 {
      margin-bottom: 1em;
    }
  }

  &__right {
    flex: 0.6;
  }

  &__content {
    padding-bottom: 30px;
  }

  &__continue {
    height: 100px;
  }
}